import toast from "react-hot-toast";
import commaNumber from "comma-number";

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const setWithExpiry = (key, value, ttl = 6) => {
  const now = new Date();
  const expiry = ttl * 3600000;

  const item = {
    value: value,
    expiry: now.getTime() + expiry,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const removeWithExpiry = (key) => {
  localStorage.removeItem(key);
  return true;
};

export const checkShippigZone = (doc) => {
  if (doc.country === "") {
    toast.error("Country is not allowed to be empty");
    return false;
  }

  if (!Array.isArray(doc.states) || doc.states.length === 0) {
    toast.error("State must contain at least one item");
    return false;
  }

  if (!Array.isArray(doc.rates) || doc.rates.length === 0) {
    toast.error("Rates must contain at least one item");
    return false;
  }

  return true;
};

export const planName = (doc) => {
  if (doc === "GROWTH") {
    return "Growth";
  } else if (doc === "GROWTH - Yearly") {
    return "Growth - Yearly";
  } else if (doc === "ENTERPRISE") {
    return "Enterprise";
  } else {
    return "Starter";
  }
};

export const planDescription = (doc) => {
  const descriptionMapping = {
    GROWTH:
      "Get more API calls, SKUs, add more merchant stores and get more value.",
    "GROWTH - Yearly":
      "Get more API calls, SKUs, add more merchant stores and get more value.",
    ENTERPRISE:
      "Contact us for any custom service that would enable your business get the best service it needs.",
  };

  return (
    descriptionMapping[doc] ||
    "Enjoy unlimited test credentials to test in sandbox environment plus 250 free API calls to make live API calls in production."
  );
};

export const planPricing = (doc) => {
  const priceMap = {
    GROWTH: `NGN${commaNumber(doc?.amount)}/month`,
    "GROWTH - Yearly": `NGN${commaNumber(doc?.amount)}/yearly`,
    ENTERPRISE: "Custom",
  };

  return priceMap[doc.plan_name] || "Freemium";
};

export const planButton = (doc, active) => {
  if (!active && doc.plan_name === "FREEMIUM") {
    return "Select Plan";
  } else if (doc.plan_name === "GROWTH" && !active) {
    return "Upgrade to plan";
  } else if (doc.plan_name === "GROWTH - Yearly" && !active) {
    return "Upgrade to plan";
  } else if (doc.plan_name === "ENTERPRISE" && !active) {
    return "Contact Sales";
  } else {
    return "Select Plan";
  }
};

export const planCode = (doc) => {
  if (doc === "GRO001") {
    return "Growth";
  } else if (doc === "GRO002") {
    return "Growth - Yearly";
  } else if (doc === "ENT001") {
    return "Enterprise";
  } else {
    return "Starter";
  }
};
